import create from 'zustand'

export const useUserStore = create((set) => ({
  loggedInStatus: false,
  userId: '',
  email: '',
  role: '',
  token: '',
  emri: '',
  mbiemri: '',
  ministriId: '',
  redirectPath: '',
  krijoTakimPopup: false,
  openKrijoTakimPopup: () => set((state) => ({ ...state, krijoTakimPopup: true })),
  closeKrijoTakimPopup: () => set((state) => ({ ...state, krijoTakimPopup: false })),
  logIn: ({ userId, email, role, token, emri, mbiemri, ministriId }) =>
    set(() => {
      localStorage.setItem('loggedIn', true)
      localStorage.setItem('userId', userId)
      localStorage.setItem('email', email)
      localStorage.setItem('role', role)
      localStorage.setItem('token', token)
      localStorage.setItem('emri', emri)
      localStorage.setItem('mbiemri', mbiemri)
      localStorage.setItem('ministriId', ministriId)

      return {
        loggedInStatus: true,
        userId,
        email,
        role,
        token,
        emri,
        mbiemri,
        ministriId,
      }
    }),
  logOut: () =>
    set(() => {
      localStorage.clear()
      return { loggedInStatus: false }
    }),
  checkIfLoggedIn: () =>
    set(() => {
      const loggedInStatus = localStorage.getItem('loggedIn')
      const userId = localStorage.getItem('userId')
      const email = localStorage.getItem('email')
      const role = localStorage.getItem('role')
      const token = localStorage.getItem('token')
      const emri = localStorage.getItem('emri')
      const mbiemri = localStorage.getItem('mbiemri')
      const ministriId = localStorage.getItem('ministriId')
      return {
        loggedInStatus,
        userId,
        email,
        role,
        token,
        emri,
        mbiemri,
        ministriId,
      }
    }),
  setRedirectPath: ({ redirectPath }) => set(() => ({ redirectPath })),
}))
