import axios from 'axios'
import { constants } from '../utils/constants'
import { useUserStore } from '../context/user'
import { gql, GraphQLClient } from 'graphql-request'
import { useQuery } from 'react-query'
import { getClient } from './kontributet'

export const login = async ({ email, password }) => {
  const payload = { email: email.toLowerCase(), password }
  const { data } = await axios.post(`${constants.authUrl}/login`, payload)
  return data
}

export const refresh = async ({ token, logIn, history, logOut, redirectPath }) => {
  try {
    const payload = {
      token,
    }
    const { data } = await axios.post(`${constants.authUrl}/refresh`, payload)
    const decodedToken = decodeToken(`${data.access_token}`)
    const {
      'https://hasura.io/jwt/claims': { 'x-hasura-ministri-id': ministriId },
    } = decodedToken

    logIn({
      userId: data.user_id,
      email: data.email,
      role: data.user_roles,
      token: data.access_token,
      emri: data.emri,
      mbiemri: data.mbiemri,
      ministriId,
    })

    if (redirectPath !== '') {
      history.push(redirectPath)
    } else if (role === 'koordinator') {
      history.push('/adminpanel')
    } else {
      history.push(`/ministrite/${ministriId}`)
    }
  } catch (e) {
    console.log({ e })
    logOut()
  }
}

export const createPersonKontakti = async ({
  emri,
  mbiemri,
  email,
  password,
  roli,
  ministri_id,
}) => {
  const payload = { emri, mbiemri, email: email.toLowerCase(), password, roli, ministri_id }

  const mytoken = localStorage.getItem('token')


  try {
    const { data } = await axios.post(`${constants.functionsUrl}/createUser`, payload, {
      headers: { Authorization: `Bearer ${mytoken}` },
    })
    return data
  } catch (error) {
    console.log(error)
  }
}

export const updateUserPass = async ({ userId, newPassword }) => {
  const client = getClient()

  const {
    update_perdoruesit_by_pk: { id },
  } = await client.request(
    gql`
      mutation {
        update_perdoruesit_by_pk(_set: { password: "${newPassword}" }, pk_columns: { id: ${userId} }) {
          id
        }
      }
    `,
  )
  return !!id ? { ok: true } : { ok: false }
}

export const useGetOldPassword = ({ userId }) => {
  const client = getClient()
  return useQuery(['oldPass', userId], async () => {
    const {
      perdoruesit_by_pk: { password },
    } = await client.request(
      gql`
      query {
        perdoruesit_by_pk(id: ${userId}) {
          password
        }
      }
    `,
    )
    return password
  })
}

export const resendOtpCode = async ({ values }) => {
  try {
    const { email } = values
    let cleanedEmail = email.toLowerCase()
    cleanedEmail = cleanedEmail.trim()
    const resp = await fetch(`${constants.authUrl}/reset`, {
      method: 'POST',
      body: JSON.stringify({
        email: cleanedEmail,
      }),
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
    const res = await resp.json()
    if (resp.ok) {
      return { ok: true, res }
    } else {
      throw res
    }
  } catch (e) {
    console.log({ e })
    const emptyResp = {}
    return { ok: false, res: emptyResp }
  }
}

export const resetPassword = async ({ values }) => {
  try {
    const { email, otpCode, newPassword } = values
    let cleanedEmail = email.toLowerCase()
    cleanedEmail = cleanedEmail.trim()
    const resp = await fetch(`${constants.authUrl}/newPass`, {
      method: 'POST',
      body: JSON.stringify({
        email: cleanedEmail,
        code: otpCode,
        password: newPassword,
      }),
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
    const res = await resp.json()
    if (resp.ok) {
      return { ok: true, res }
    } else {
      throw res
    }
  } catch (e) {
    console.log({ e })
    const emptyResp = {}
    return { ok: false, res: emptyResp }
  }
}
