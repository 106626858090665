import React, { lazy, useEffect } from 'react'
import { Route, Switch, BrowserRouter as Router, useHistory } from 'react-router-dom'
import { useUserStore } from './context/user'
import { QueryClient, QueryClientProvider } from 'react-query'
import { refresh } from './actions/auth'
import decodeToken from 'jwt-decode'
import history from './utils/history'
const DefaultLayout = lazy(() => import('./layout/DefaultLayout'))
const Login = lazy(() => import('./pages/login/Login'))
const ProtectedRoute = lazy(() => import('./utils/ProtectedRoute'))
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { Suspense } from 'react'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 0,
      },
    },
  })

  const { loggedInStatus, checkIfLoggedIn, token, logIn, logOut, redirectPath } = useUserStore()

  useEffect(() => {
    checkIfLoggedIn()
  }, [])

  useEffect(async () => {
    if (token) {
      try {
        const decodedToken = decodeToken(`${token}`)
        if (decodedToken.exp > Date.now() / 1000) {
          console.log('refreshing global state data')
          const {
            email,
            'https://hasura.io/jwt/claims': {
              'x-hasura-ministri-id': ministriId,
              'x-hasura-default-role': role,
              emri,
              mbiemri,
              'x-hasura-user-id': userId,
            },
          } = decodedToken

          logIn({
            userId,
            email,
            role,
            token,
            emri,
            mbiemri,
            ministriId,
          })

          if (redirectPath !== '') {
            history.push(redirectPath)
          } else if (role === 'koordinator' || role === 'mini_koordinator') {
            history.push('/adminpanel')
          } else {
            history.push(`/ministrite/${ministriId}`)
          }
        } else if (decodedToken?.exp < Date.now() / 1000 + 1790) {
          refresh({ token, logIn, history, logOut, redirectPath })
        }
      } catch (e) {
        console.log({ e })
        logOut()
      }
    }
  }, [logIn, logOut, redirectPath, token])

  const customTheme = {
    palette: {
      primary: { main: '#cf0429' },
      secondary: { main: '#cf0429' },
    },
  }

  const appliedTheme = createMuiTheme(customTheme)

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={appliedTheme}>
          <Suspense fallback={loading}>
            <Switch>
              <Route path="/login" name="Login Page" render={(props) => <Login {...props} />} />
              <ProtectedRoute path="*" loggedIn={loggedInStatus} component={DefaultLayout} />
            </Switch>
          </Suspense>
        </ThemeProvider>
      </QueryClientProvider>
    </Router>
  )
}

export default App
