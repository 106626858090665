import { gql, GraphQLClient } from 'graphql-request'
import { useQuery } from 'react-query'
import { constants } from '../utils/constants'

export const getClient = () => {
  const token = localStorage.getItem('token')
  const graphqlClient = new GraphQLClient(constants.hasuraUrl)

  graphqlClient.setHeader('Authorization', `Bearer ${token}`)
  return graphqlClient
}

export const useGetKontributiInfo = (kontribut_id) => {
  const client = getClient()

  return useQuery(['kontribut', kontribut_id], async () => {
    const { kontributet_by_pk } = await client.request(
      gql`
      query GetKontribut {
        kontributet_by_pk(id:${kontribut_id}) {
                 id
                 perdoruesit {
                   emri
                   mbiemri
                 }
                 case_nr
                 shtetet {
                   shteti
                 }
                 kthimi
                 nivelet_e_edukimit {
                   info
                 }
                 profesioni
                 vite_eksperience
                 
                 menyrat_e_angazhimit {
                   info
                 }
                 eventet{
                  id
                  ora_takimit
                  created_at
                  status_kontributet{
                    info
                    status_kontributi
                  }
  
                }
                 created_at
                 files{
                  id
                  name
                }
               }
             }

          `,
    )
    return kontributet_by_pk
  })
}

export const useGetKomunikimet = ({ kontribut_id, limit }) => {
  const client = getClient()

  return useQuery(['komunikim', kontribut_id], async () => {
    const { komunikimet } = await client.request(
      gql`
      query GetKomunikimet{
        komunikimet(
          where: { kontribut_id: { _eq: ${kontribut_id} } }
          order_by: { created_at: desc }
          limit:${limit}
        ) {
          id
          tekst
          seen_by_qytetar
          seen_by_qeveritar
          created_at
          kontributet{
            perdoruesit{
              emri
              mbiemri
            }
          }
          perdoruesit {
            id
            emri
            mbiemri
            institucionet {
              institucioni
            }
          }
          files {
            id
            name
          }
        }
      }



          `,
    )

    return komunikimet
  })
}

export const useGetInstitucionet = () => {
  const client = getClient()
  return useQuery(['institucionet'], async () => {
    const { institucionet } = await client.request(
      gql`
        query GetInstitucionet {
          institucionet {
            id
            institucioni
            perdoruesits(
              where: {  _and:[{roli: { _eq: person_kontakti }},{ deleted: { _eq: false } },{skifter:{_eq:false}}]  }
            ) {
              id
              emri
              mbiemri
              email
            }
          }
        }
      `,
    )

    return institucionet
  })
}

export const useGetMinCardZhavorr = ({ id }) => {
  const client = getClient()
  return useQuery(['getMinZhavorr', id], async () => {
    const {
      kontributetCreatedAt,
      kontributet_aggregate,
      kontributetseen,
      latestKomunikim,
      takimeCount,
      topiTakimeve,
    } = await client.request(
      gql`
      query Test {
        kontributetCreatedAt: kontributet(order_by: {created_at: desc}, limit: 1, where: {kontributet_fushat_e_interesits: {fushat_e_interesit: {institucion_id: {_eq: ${id}}}}}) {
          created_at
        }
        kontributet_aggregate(where: {kontributet_fushat_e_interesits: {fushat_e_interesit: {institucion_id: {_eq: ${id}}}}}) {
          aggregate {
            count
          }
        }
        kontributetseen: kontributet(limit: 1, where: {_and: [{seen_by_qeveritar: {_eq: false}}, {kontributet_fushat_e_interesits: {fushat_e_interesit: {institucion_id: {_eq: ${id}}}}}]}) {
          seen_by_qeveritar
        }
        takimeCount:eventet_aggregate(where:{_and:[{kontributets:{kontributet_fushat_e_interesits:{fushat_e_interesit:{institucion_id:{_eq:${id}}}}}},
          {_or:[{status:{_eq:takim_me_min}},{status:{_eq:takim_me_km}}]}]}){
          aggregate{
            count
          }
        }
        topiTakimeve:eventet(where:{_and:[{kontributets:{kontributet_fushat_e_interesits:{fushat_e_interesit:{institucion_id:{_eq:${id}}}}}},
          {_or:[{status:{_eq:takim_me_min}},{status:{_eq:takim_me_km}}]}]}){
          ora_takimit
        }
        latestKomunikim:komunikimet(
                  order_by: { created_at: desc }
                  limit: 1
                  where: {
                    kontributet: {
                      kontributet_fushat_e_interesits: {
                        fushat_e_interesit: { institucion_id: { _eq: ${id} } }
                      }
                    }
                  }
                 ) {
                  created_at
                }
      }
      `,
    )
    let kontributIFundit
    try {
      kontributIFundit = kontributetCreatedAt[0].created_at
    } catch {
      kontributIFundit = null
    }

    let komunikimIFundit
    try {
      komunikimIFundit = latestKomunikim[0].created_at
    } catch {
      komunikimIFundit = null
    }

    let aktivitetiFundit =
      new Date(kontributIFundit) > new Date(komunikimIFundit) ? kontributIFundit : komunikimIFundit

    let anyNew
    try {
      anyNew = kontributetseen[0].seen_by_qeveritar
    } catch {
      anyNew = null
    }
    let anyNewKontribut = anyNew === null ? false : true

    let newTakim = false

    topiTakimeve.map((takim) => {
      if (new Date(takim.ora_takimit) > new Date()) {
        newTakim = true
      }
      return
    })

    let res = {
      aktivitetiFundit: aktivitetiFundit,
      mycount: kontributet_aggregate.aggregate.count,
      anyNewKontribut: anyNewKontribut,
      takimeCount: takimeCount.aggregate.count,
      newTakim: newTakim,
    }

    return res
  })
}

export const useGetInstitucion = (id) => {
  const client = getClient()
  return useQuery(['institucion', id], async () => {
    const { institucionet_by_pk } = await client.request(
      gql`
      query GetInstitucion{
        institucionet_by_pk(id:${id}){
          institucioni
          }
      }
      `,
    )

    return institucionet_by_pk.institucioni
  })
}

export const useDeletePersonKontakti = async (id) => {
  const client = getClient()

  const { delete_perdoruesit_by_pk } = await client.request(
    gql`
    mutation RemovePersonKontakti {
      update_perdoruesit_by_pk(pk_columns:{id:${id}}_set:{deleted:true}){
        deleted
      }
    }
      `,
  )
  return delete_perdoruesit_by_pk
}

export const useUpdateSeenByQeveritar = async (id) => {
  const client = getClient()

  const { update_komunikimet_by_pk } = await client.request(
    gql`
    mutation SeenByQeveritar {
      update_komunikimet(where:{id:{_eq:${id}}},_set:{seen_by_qeveritar:true}){
      affected_rows
    }
    }
      `,
  )
  return update_komunikimet_by_pk
}

export const useGetKomunikimetAgg = (id) => {
  const client = getClient()
  return useQuery(['komunikimetAgg', id], async () => {
    const { komunikimet_aggregate } = await client.request(
      gql`
      query GetKomunikimetCount{
        komunikimet_aggregate(where: { kontribut_id: { _eq: ${id} } }){
          aggregate{
            count
          }
        }
      }
          `,
    )
    return komunikimet_aggregate.aggregate.count
  })
}

export const useGetCaseNr = (id) => {
  const client = getClient()
  return useQuery(['caseNr', id], async () => {
    const { kontributet_by_pk } = await client.request(
      gql`
      query GetKontributCaseNr {
        kontributet_by_pk(id:${id}){
          case_nr
        }
      }
      `,
    )

    return kontributet_by_pk.case_nr
  })
}

export const useSeenKontribut = async (id) => {
  const client = getClient()

  const { update_kontributet_by_pk } = await client.request(
    gql`
    mutation SetKontributSeen{
      update_kontributet_by_pk(pk_columns:{id:${id}}_set:{seen_by_qeveritar:true}){
        seen_by_qeveritar
      }
    }
      `,
  )
  return update_kontributet_by_pk
}

export const useGetGjitheCaseNrKontributet = ({ institucionId, isPopupOpen }) => {
  const client = getClient()
  return useQuery(
    ['gjitheCaseNrKontributet', institucionId],
    async () => {
      const { kontributet } = await client.request(
        gql`
        query {
          kontributet(where: {kontributet_fushat_e_interesits: {fushat_e_interesit: {institucion_id: {_eq: ${institucionId}}}}}) {
            id
            perdoruesit {
              emri
              mbiemri
            }
            case_nr
          }
        }
        
        `,
      )
      return kontributet
    },
    {
      enabled: !!institucionId && !!isPopupOpen,
    },
  )
}

export const useSetKontributPerfunduar = async ({ kontributId, qeveritarId }) => {
  const client = getClient()

  const { insert_eventet_one } = await client.request(
    gql`
    mutation PerfundoEvent {
      insert_eventet_one(object:{kontribut_id:${kontributId},qeveritar_id:${qeveritarId},status:perfunduar}){
        id
      }
    }        
      `,
  )
  return insert_eventet_one
}
