export const constants = {
  authUrl: process.env.REACT_APP_AUTH_URL || 'https://auth.gatipershqiperine.al',
  functionsUrl: process.env.REACT_APP_FUNCTION_URL || 'https://func.gatipershqiperine.al/api',
  hasuraUrl: process.env.REACT_APP_HASURA_URL || 'https://api.gatipershqiperine.al/v1/graphql',
  fileUploadUrl:
    process.env.REACT_APP_FILE_UPLOAD_URL || 'https://func.gatipershqiperine.al/api/uploads',
  kontributetPageSize: process.env.PAGE_SIZE || 15,
  komunikimPageSize: process.env.KOMUNIKIM_PAGE_SIZE || 5,

  supportedFileFormats: [
    '.doc',
    '.docs',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
  ],
  maxUploadSize: 15000000,
  roletAlt: [
    { id: 1, roli: 'Vëzhgues', dbValue: 'person_kontakti' },
    { id: 2, roli: 'I deleguar', dbValue: 'deleguar' },
    { id: 3, roli: 'zv. Koordinator', dbValue: 'mini_koordinator' },
  ],
  requiredErrorMessage: 'Fusha më lart është e detyrueshme.',
  shortPassErrorMessage:
    'Fjalëkalimi juaj është shumë i shkurtër, ai duhet të përmbajë të paktën 8 karaktere.',
  passWrongFormatErrorMessage:
    'Fjalëkalimi juaj duhet të përmbajë të paktën një karakter të vogël, një karakter të madh, një numër dhe një karakter special.',
  notSamePassErrorMessage: 'Fjalëkalimi nuk është i njëjtë me atë të vendosur më lart.',
  dokumentTooBigErrorMessage: 'Dokumenti është shumë i madh për tu dërguar.',
  dokumentWrongFormatErrorMessage: 'Dokumenti nuk është në një prej formateve të lejuara.',
  emailWrongFormatErrorMessage: 'Emaili nuk është në formatin e duhur.',
  codeShortErrorMessage: 'Kodi duhet të jetë të paktën 5 karaktere.',
  statuset_e_eventet: [
    { id: 1, statusi: 'Takim me Kryeministrin', dbValue: 'takim_me_km', roli: 'koordinator' },
    { id: 2, statusi: 'Takim me Ministrin', dbValue: 'takim_me_min', roli: 'person_kontakti' },
  ],
}
